<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
      @getSize="getSize"
    />
    <MatchList ref="matchListRef"></MatchList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter";
import MatchList from "./components/MatchList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportMatch",
  components: {
    MatchList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const matchListRef = ref();
    const handleSearch = (searchParams) => {
      matchListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      matchListRef.value.doSearch();
    };

    const exportClick = (formState) => {
      const beginDate = formState.beginDate
        ? formState.beginDate
        : "";
      const endDate = formState.endDate
        ? formState.endDate
        : "";
      const params = {
        ...formState,
        beginDate,
        endDate,
      };
      $api.preMatchingExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    const getSize = () => {
      matchListRef.value.getSize();
    };
    return {
      matchListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      getSize,
    };
  },
});
</script>
